/*
**************************************************************************************************************
                                                R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
    Author	:	
    Date	:	 
**************************************************************************************************************
                                                Usage Notes
**************************************************************************************************************

**************************************************************************************************************
                                                Amendments
**************************************************************************************************************
    M001 : 24 August 2023 : Zeeshan Siddique
         * M-4458: Clicking on the checkbox and registering in the table of registration events does not work
**************************************************************************************************************
*/

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as connectAction from '../../../actionCreators/Connect';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import Confirm from './signupConfirmModal';
import SignUpForm from './signupForm';
import FullPageLoader from '../../../components/FullPageLoader';
import { Form, Button, Image } from 'react-bootstrap';

const SignupTable = ({ getEvents, events, registerEvents, loading }) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmEvtList, setShowConfirmEvtList] = React.useState([]);
  const [hackLoading, setHackLoading] = React.useState(false);

  React.useEffect(() => {
    getEvents();
  }, []);

  if (loading || hackLoading) {
    return <FullPageLoader></FullPageLoader>;
  }

  const showPopUp = event => {
    const target = event.target;
    const checkedEvents = [];
    if (target.getElementsByTagName('input')) {
      [...target.getElementsByTagName('input')].forEach(item => {
        //M001
        if (item.checked) {
          checkedEvents.push(parseInt(item.value));
        }
      });
    }
    if (checkedEvents.length > 0) {
      var confrimedEvts = [];
      events.forEach(event => {
        if (checkedEvents.indexOf(event.iCAE_Id) != -1) {
          confrimedEvts.push(event);
        }
      });
      setShowConfirmEvtList(confrimedEvts);
      setShowConfirm(true);
    }
    event.preventDefault();
  };

  const createEvtForm = events => {
    if (events.length == 0) {
      return <SignUpForm title={'Events'} showButton={false} />;
    } else {
      var rtnElements = [];
      var campaigns = [];
      events.sort((a, b) => new Date(a.dUTC_EvtDate) - new Date(b.dUTC_EvtDate));

      events.forEach(item => {
        if (campaigns.indexOf(item.iCMP_Id) === -1) {
          campaigns.push(item.iCMP_Id);
        }
      });
      campaigns.forEach(campaign => {
        var campDataSet = [];
        events.forEach(event => {
          if (campaign === event.iCMP_Id) {
            campDataSet.push(event);
          }
        });
        rtnElements.push(
          <SignUpForm title={campDataSet[0].sCmpTitle} data={campDataSet} showButton={true} />
        );
      });
      return rtnElements;
    }
  };

  const submitEvt = () => {
    registerEvents({ submitEvts: confirmEvtList });
    setHackLoading(true);
    setTimeout(function () {
      setHackLoading(false);
      getEvents();
    }, 1500);
  };

  return (
    <>
      <Confirm
        show={showConfirm}
        onConfirm={() => {
          setShowConfirm(false);
          submitEvt();
        }}
        onCancel={() => {
          setShowConfirm(false);
        }}
        evtList={confirmEvtList}
      />
      <Form className="box connect-table" onSubmit={showPopUp}>
        {createEvtForm(events)}
        <Button bsStyle="primary" type="submit">
          Register
        </Button>
      </Form>
    </>
  );
};

function mapStateToProps(state) {
  const events = state.connect.events;
  const loading = state.connect.loading;
  return { loading, events };
}

SignupTable.propTypes = {
  getEvents: PropTypes.func.isRequired,
  events: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

SignupTable.defaultProps = {
  events: [],
};

const mapDispatchToProps = dispatch => ({
  getEvents: bindActionCreators(connectAction.getEvents, dispatch),
  registerEvents: bindActionCreators(connectAction.registerEvents, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupTable));
