import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';

import LoginForm from './components/Login';
import FormContainer from '../../components/FormContainer';

import * as userActions from '../../actionCreators/User';

import MainLayout from '../../layouts/Main';

const Container = ({ user, history, logIn, location, initialValues, resetState }) => {
  useEffect(() => {
    resetState();
  }, []);

  const locate = useLocation();

  let urlParameters = locate.pathname.split('/');
  var redirectname = urlParameters[2];
  let redirectUrl;

  const { search } = location;
  const urlParams = new URLSearchParams(search);
  let externalUrl = urlParams.get('redirectUrl');
  externalUrl = externalUrl ? `/rfs.htm${externalUrl}` : null;
  redirectUrl =
    location.state && location.state.redirectUrl
      ? location.state && location.state.redirectUrl
      : externalUrl;
  // if (!redirectUrl) {
  //   redirectUrl = `/rfs.htm${search}`;
  // }
  if (redirectname == 'signupPreference') {
    redirectUrl = `/subscriptionPreference/home`;
  }
  return (
    <div className="login-signup-page">
      <FormContainer>
        <div style={{ paddingTop: 15 }} />
        <div style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold' }}>Login</div>
        <LoginForm
          onSubmit={obj => logIn(obj, history, redirectUrl)}
          message={user.loginError}
          initialValues={{
            email: (initialValues && initialValues.email) || urlParams.get('email'),
          }}
          showSignUpMessage
        />
      </FormContainer>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = dispatch => ({
  logIn: bindActionCreators(userActions.logIn, dispatch),
  resetState: bindActionCreators(userActions.resetState, dispatch),
});

Container.propTypes = {
  user: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  logIn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  resetState: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirectUrl: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
