/*
**************************************************************************************************************
												R&D TECH LIMITED
**************************************************************************************************************
The contents of this file ("Software") is the property of R&D Tech Limited
and is protected by copyright and other intellectual property laws and treaties.

R&D Tech Limited owns the title, copyright, and other intellectual property rights in the Software.

Usage of the Software including but not limited to execution of the Software, the creation of
copies of the Software, redistribution of the Software and creation of derived works from the
Software (and the redistribution thereof) are subject to the terms of a license agreement issued
by R&D Tech Limited.

If the person in possession of the Software has not entered into a license agreement with
R&D Tech Limited, then any use of the Software for any purpose whatsoever is strictly prohibited.
**************************************************************************************************************
	Author	:	Nick Ng
	Date	:	10 Oct 2022
**************************************************************************************************************
												Usage Notes
**************************************************************************************************************

**************************************************************************************************************
												Amendments
**************************************************************************************************************
  M003 : 17 Sep 2022 : Nick Ng
    * M-4173: Resend of email verification email if the user tries to login or clicks on Forgot Password
  M002 : 24 Nov 2020 : Nick
    * M-3067: Using user name search to reset password does not retain the result list after the email is sent out
  M001 : 03 Sep 2020 : Nicholas
    * M-2988: User list reset after searching for and removing a user
**************************************************************************************************************
*/

const initialState = {
  success: false,
  signUpSuccess: false,
  login: false,
  loading: false,
  loginError: null,
  signUpError: null,
  currentUser: {
    data: null,
    loading: false,
    error: null,
    loaded: false, //M003
  },
  openModal: true,
  deleteUserModal: false,
  PersonMatrixDetails: {
    loading: false,
    error: null,
    data: [],
  },
  UpdatePersonMatrixDetails: {
    loading: false,
    error: null,
    data: [],
  },
  AccountManager: {
    loading: false,
    error: null,
    data: [],
  },
  SendEmail: {
    loading: false,
    error: null,
    data: [],
  },
  lastUnsubEmailSent: {
    loading: false,
    error: null,
    data: [],
  },
  unsubReason: {
    loading: false,
    error: null,
    data: [],
  },
};

export default (state = initialState, { obj, type, error, payload, data, pagination }) => {
  switch (type) {
    case 'SIGNUP':
    case 'LOGIN':
    case 'LOGOUT':
      return {
        ...state,
        ...initialState,
        type,
        loading: true,
        //================M003=========[=============
        currentUser: {
          ...state.currentUser,
          loaded: true,
        },
        //================M003=========]=============
      };

    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        loading: false,
        // success: true,
        signUpSuccess: true,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          error: null,
          loaded: true, //M003
        },
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        ...initialState,
        type,
        loading: false,
        //================M003=========[=============
        currentUser: {
          data: null,
          loading: false,
          error: null,
          loaded: true,
        },
        //================M003=========]=============
      };

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        login: true,
        success: true,
        loading: false,
        type,
        payload,
        currentUser: {
          ...state.currentUser,
          loading: false,
          data,
          loaded: true, //M003
        },
      };

    case 'LOGIN_FAIL':
      return {
        ...state,
        loginError: error,
        success: false,
        loading: false,
        type,
        currentUser: {
          loaded: true,
        },
      };

    case 'SIGNUP_FAIL':
    case 'LOGOUT_FAIL':
      return {
        ...state,
        signUpError: error,
        success: false,
        loading: false,
        type,
        //================M003=========[=============
        currentUser: {
          ...state.currentUser,
          loaded: true,
        },
        //================M003=========]=============
      };

    case 'REMOVE_USER':
    case 'REMOVE_USER_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map(item =>
            item.id === obj.id
              ? {
                  ...item,
                  hide: true,
                }
              : item
          ),
          //====================M001==========================
        },
        deleteUserModal: false,
      };

    case 'RESET_STATE':
      return {
        ...state,
        loginError: null,
        signUpError: null,
      };

    case 'REMOVE_USER_FAIL':
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users,
          filteredData: state.users,
          error,
        },
        deleteUserModal: false,
      };
    case 'USER_DELETE_MODAL':
      return {
        ...state,
        deleteUserModal: obj.userModal,
      };

    case 'CHANGE_PASSWORD':
      return {
        ...state,
        ...initialState,
        obj,
        loading: true,
      };

    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        login: true,
        success: true,
        loading: false,
        message: obj.message,
        payload,
        error: null,
      };

    case 'CHANGE_PASSWORD_FAIL':
      return {
        ...state,
        error,
        success: false,
        loading: false,
        obj,
        message: null,
      };

    case 'SAVE_UNSUBSCRIBE_REASON':
      return {
        ...state,
        unsubReason: {
          ...state.unsubReason,
          loading: true,
        },
      };

    case 'SAVE_UNSUBSCRIBE_REASON_SUCCESS':
      return {
        ...state,
        unsubReason: {
          ...state.unsubReason,
          loading: false,
          success: true,
          data,
        },
      };

    case 'SAVE_UNSUBSCRIBE_REASON_FAILURE':
      return {
        ...state,
        unsubReason: {
          ...state.unsubReason,
          loading: false,
          error,
        },
      };

    case 'GET_CURRENT_USER':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loading: true,
          error: null,
          data: null,
        },
      };

    case 'GET_CURRENT_USER_SUCCESS':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loading: false,
          data,
          loaded: true, //M003
        },
        openModal: false,
      };

    case 'GET_CURRENT_USER_FAIL':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loading: false,
          error,
          loaded: true, //M003
        },
      };

    case 'GET_PERSON_MATRIX_DETAILS':
      return {
        ...state,
        PersonMatrixDetails: {
          ...state.PersonMatrixDetails,
          loading: true,
        },
      };

    case 'GET_PERSON_MATRIX_DETAILS_SUCCESS':
      return {
        ...state,
        PersonMatrixDetails: {
          ...state.PersonMatrixDetails,
          loading: false,
          success: true,
          data,
        },
      };

    case 'GET_PERSON_MATRIX_DETAILS_FAILURE':
      return {
        ...state,
        PersonMatrixDetails: {
          ...state.PersonMatrixDetails,
          loading: false,
          error,
        },
      };

    case 'UPDATE_PERSON_MATRIX_DETAILS':
      return {
        ...state,
        UpdatePersonMatrixDetails: {
          ...state.UpdatePersonMatrixDetails,
          loading: true,
        },
      };

    case 'UPDATE_PERSON_MATRIX_DETAILS_SUCCESS':
      return {
        ...state,
        UpdatePersonMatrixDetails: {
          ...state.UpdatePersonMatrixDetails,
          loading: false,
          success: true,
          data,
        },
      };

    case 'UPDATE_PERSON_MATRIX_DETAILS_FAILURE':
      return {
        ...state,
        UpdatePersonMatrixDetails: {
          ...state.UpdatePersonMatrixDetails,
          loading: false,
          error,
        },
      };

    case 'GET_ACCOUNT_MANAGER':
      return {
        ...state,
        AccountManager: {
          ...state.AccountManager,
          loading: true,
        },
      };

    case 'GET_ACCOUNT_MANAGER_SUCCESS':
      return {
        ...state,
        AccountManager: {
          ...state.AccountManager,
          loading: false,
          success: true,
          data,
        },
      };

    case 'GET_ACCOUNT_MANAGER_FAILURE':
      return {
        ...state,
        AccountManager: {
          ...state.AccountManager,
          loading: false,
          error,
        },
      };

    case 'SEND_EMAIL':
      return {
        ...state,
        SendEmail: {
          ...state.SendEmail,
          loading: true,
        },
      };

    case 'SEND_EMAIL_SUCCESS':
      return {
        ...state,
        SendEmail: {
          ...state.SendEmail,
          loading: false,
          success: true,
          data,
        },
      };

    case 'SEND_EMAIL_FAILURE':
      return {
        ...state,
        SendEmail: {
          ...state.SendEmail,
          loading: false,
          error,
        },
      };

    case 'GET_USER_UNSUB_LAST_SENT_EMAIL':
      return {
        ...state,
        lastUnsubEmailSent: {
          ...state.lastUnsubEmailSent,
          loading: true,
        },
      };

    case 'GET_USER_UNSUB_LAST_SENT_EMAIL_SUCCESS':
      return {
        ...state,
        lastUnsubEmailSent: {
          ...state.lastUnsubEmailSent,
          loading: false,
          success: true,
          data,
        },
      };

    case 'GET_USER_UNSUB_LAST_SENT_EMAIL_FAILURE':
      return {
        ...state,
        lastUnsubEmailSent: {
          ...state.lastUnsubEmailSent,
          loading: false,
          error,
        },
      };

    default:
      return state;
  }
};
